import Layout from "@components/layout";

import React, { useState, useRef, useEffect } from "react";
import { withMKBList, withMKBPlayers } from "@helpers/hoc/withQueries";
import { withMutateAddMatch } from "@helpers/hoc/withMutations";
import { navigate } from "gatsby";
import { Toast } from "antd-mobile";
import SEO from "@components/common/seo";
import { useFormContext } from "@helpers/hooks/formContext"; // Adjust the import path

import "@styles/global.styles.css";
import { Collapse, Select, Form, Button, DatePicker, InputNumber } from "antd";
import {
  FireOutlined,
  StarOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

import dayjs from "dayjs";
import {
  extractGuestData,
  useUniqueSelectionsValidator,
  isBrowser,
} from "@helpers/tools";

type Props = {
  location?: {
    pathname: string;
  };
  mkbPlayers: {
    notionMKBPlayers: any;
  };
  matchCreate: Function;
  loadingMkbPlayers: boolean;
};

const Home: React.FC<Props> = ({
  location,
  mkbPlayers,
  matchCreate,
  loadingMkbPlayers,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { formValues } = useFormContext();
  const formRef = useRef(null);

  const select = extractGuestData(
    mkbPlayers?.notionMKBPlayers?.data?.results
  )?.sort((a, b) => b.handicap - a.handicap);

  const validateUniqueSelections = useUniqueSelectionsValidator(form, [
    "wp1",
    "wp2",
    "lp1",
    "lp2",
  ]);

  useEffect(() => {
    if (formValues) {
      form.setFieldsValue(formValues);
    }
  }, [formValues, form]);
  const username = isBrowser() && localStorage.getItem("username");

  return (
    <Layout location={location.pathname}>
      <SEO title="Badminton" />
      <Form
        ref={formRef}
        form={form}
        layout="vertical"
        className="m-2"
        initialValues={{ todayDate: dayjs() }}
        onFinish={(values: any) => {
          setLoading(true);
          matchCreate({
            variables: {
              input: values,
              userAgent: navigator.userAgent,
              addedBy: username,
            },
          });
          Toast.show({
            icon: "success",
            content: "Match Saved",
          });
          setLoading(false);
          form.resetFields();
          // navigate("/today");
        }}
      >
        <Collapse
          defaultActiveKey={["1"]}
          items={[
            {
              key: "1",
              label: "Winners",
              children: (
                <>
                  <Form.Item
                    label="Player 1"
                    name="wp1"
                    tooltip={{
                      title: "Do not repeat the same player",
                    }}
                    rules={[
                      { required: true, message: "Please input your name" },
                      { validator: validateUniqueSelections },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="label"
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={select}
                      loading={loadingMkbPlayers}
                      disabled={loadingMkbPlayers}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Player 2"
                    name="wp2"
                    tooltip={{
                      title: "Do not repeat the same player",
                    }}
                    rules={[
                      { required: true, message: "Please input your name" },
                      { validator: validateUniqueSelections },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="label"
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={select}
                      loading={loadingMkbPlayers}
                      disabled={loadingMkbPlayers}
                    />
                  </Form.Item>
                </>
              ),
            },
          ]}
        />
        <div className="flex space-x-2 mt-3">
          {" "}
          <Form.Item
            className="flex-1 mb-3"
            name="loserScore"
            rules={[
              { required: true, message: "Please input your loser score" },
            ]}
            tooltip={{
              title: "max 30",
            }}
          >
            <InputNumber
              type="number"
              placeholder="Loser Score"
              className="w-full"
              prefix={<StarOutlined />}
            />
          </Form.Item>
          <Form.Item className="flex-1 mb-1" name="todayDate">
            <DatePicker />
          </Form.Item>
        </div>
        <div className="flex space-x-2 mb-3">
          {" "}
          <Button
            block
            icon={<FireOutlined />}
            type="primary"
            htmlType="submit"
            loading={loading}
            className="flex-1"
          >
            ADD MATCH
          </Button>
          <Button
            block
            icon={<UserSwitchOutlined />}
            onClick={() => {
              const form = formRef.current;
              if (form) {
                const values = form.getFieldValue();

                form.setFieldsValue({
                  wp1: values.lp1,
                  wp2: values.lp2,
                  lp1: values.wp1,
                  lp2: values.wp2,
                });
              }
            }}
            className="flex-1" // Ensure both buttons take equal space
          >
            SWAP
          </Button>
        </div>

        <Collapse
          defaultActiveKey={["1"]}
          items={[
            {
              key: "1",
              label: "Loser",
              children: (
                <>
                  <Form.Item
                    label="Player 1"
                    name="lp1"
                    tooltip={{
                      title: "Do not repeat the same player",
                    }}
                    rules={[
                      { required: true, message: "Please input players" },
                      { validator: validateUniqueSelections },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="label"
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={select}
                      loading={loadingMkbPlayers}
                      disabled={loadingMkbPlayers}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Player 2"
                    name="lp2"
                    tooltip={{
                      title: "Do not repeat the same player",
                    }}
                    rules={[
                      { required: true, message: "Please input your name" },
                      { validator: validateUniqueSelections },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="label"
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={select}
                      loading={loadingMkbPlayers}
                      disabled={loadingMkbPlayers}
                    />
                  </Form.Item>
                </>
              ),
            },
          ]}
        />
      </Form>
    </Layout>
  );
};

export default withMKBPlayers(withMKBList(withMutateAddMatch(Home)));
